@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.mr-dafoe-regular {
  font-family: "Mr Dafoe", cursive;
  font-weight: 400;
  font-style: normal;
}

.kaushan-script-regular {
  font-family: "Kaushan Script", serif;
  font-weight: 400;
  font-style: normal;
}

.give-you-glory-regular {
  font-family: "Give You Glory", serif;
  font-weight: 400;
  font-style: normal;
}

.App-header {
  mix-blend-mode: hard-light;
  background-color: #343428;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(100px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
